import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoginForm from '../Components/LoginForm'
import AuthActions from '../Redux/Auth'
import {
  Container,
  Grid
} from 'semantic-ui-react'
import history from '../history'
import { api } from '../Sagas'
import { toast } from 'react-toastify'

class LoginView extends React.Component {
  componentWillMount () {
    if (this.props.isAuthenticated) {
      history.push('/')
    }
    this.state = {
      passwordResetSuccess: false
    }
    this.onPasswordReset = this.onPasswordReset.bind(this)
  }

  async onPasswordReset (eMail) {
    const res = await api.passwordReset(eMail)
    if (res.ok) {
      this.setState({ passwordResetSuccess: true })
    } else if (res.data && res.data.email) {
      toast.error("There was an error resetting your password: " + res.data.email[0])
    } else {
      toast.error("There was an error resetting your password")
    }
  }

  render () {
    return (
      <div className='page-login'>
        <Container>
          <Grid doubling centered>
            <Grid.Column textAlign='center' mobile={16} tablet={8} computer={6}>
              <LoginForm
                onSignIn={this.props.onSignIn}
                onPasswordReset={this.onPasswordReset}
                loginError={this.props.loginError} 
                passwordResetSuccess={this.state.passwordResetSuccess}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )
  }
}

LoginView.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    loginError: state.auth.error,
    isAuthenticated: state.auth.isAuthenticated
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onSignIn: (userName, password) =>
      dispatch(AuthActions.loginRequest(userName, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
