import { call, put, select } from 'redux-saga/effects'
import AuthActions from '../Redux/Auth'
import ProfileActions from '../Redux/Profile'
import StartupActions from '../Redux/Startup'
import history from '../history'
import Dispatcher from '../Utils/Dispatcher'

export const selectAuthToken = state => state.auth.token

var refreshInterval = null

// attempts to login
export function * login (api, { userName, password }) {
  // request
  const response = yield call(api.login, userName, password)

  // success?
  if (response.ok) {
    // store auth data in redux store
    yield put(AuthActions.loginSuccess(response.data))
    Dispatcher.emit('loginSuccess')
    yield put(AuthActions.whoamiRequest())
  } else {
    yield put(AuthActions.loginFailure(response.data.nonFieldErrors[0]))
  }
}

// attempts to logout
export function * logout (api) {
  yield call(api.logout)
  yield put(AuthActions.logoutSuccess())
  clearInterval(refreshInterval)
  Dispatcher.emit('logoutSuccess')
  yield history.push('/login')
}

// whoami?
export function * whoami (api, dispatch) {
  // request
  const response = yield call(api.whoami)

  // success?
  if (response.ok) {
    // store whoami data in redux store
    yield put(AuthActions.whoamiSuccess(response.data))
    const loggedIn = yield select(state => state.auth.loggedIn)
    yield put(ProfileActions.profileRequest())
    if (response.data && response.data.csrf) {
      yield call(api.setCsrfToken(response.data.csrf))
    }
    Dispatcher.emit('whoamiSuccess')
    if (loggedIn) {
      // goto page user was trying to access, if any
      let redirectUrl
      try {
        redirectUrl = yield select(
          state => state.routing.location.state.from.pathname
        )
      } catch (e) {
        redirectUrl = '/'
      }
      console.log('REDIRECT', redirectUrl)
      history.push(redirectUrl)
    }
    yield put(StartupActions.startupComplete())
  } else {
    // @CHECK do nothing for now because setting an error in the auth store causes the error modal
    // to be displayed in the login view just when loading the page
    // yield put(AuthActions.whoamiFailure('Can\'t retrieve user info'))
    yield put(StartupActions.startupComplete())
  }
}
