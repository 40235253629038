import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Input } from 'semantic-ui-react'

const headerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}

class ChangelogModal extends React.Component {
  constructor () {
    super()
    this.state = {
      searchText: ''
    }
    this.handleTextSearchChange = this.handleTextSearchChange.bind(this)
  }

  handleTextSearchChange (e, { value }) {
    this.setState({ searchText: value })
  }

  htmlChangelog () {
    if (this.state.searchText) {
      let re
      try {
        re = new RegExp(this.state.searchText, 'gi')
      } catch (err) {
        // nothing
      }
      if (re) {
        const res = this.props.changelog.replace(re, x => (
          x ? `<span class="underline">${x}</span>` : x
        ))
        return res
      }
    }
    return this.props.changelog
  }

  render () {
    return (
      <Modal
        trigger={this.props.trigger}
        open={this.props.isOpen}
        size='small'
        onClose={this.props.onClose}
      >
        <Modal.Header style={headerStyles}>
          CHANGELOG{' '}
          <Input value={this.state.searchText} icon='search' size='mini' onChange={this.handleTextSearchChange} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div dangerouslySetInnerHTML={{ __html: this.htmlChangelog() }} />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

ChangelogModal.propTypes = {
  trigger: PropTypes.object,
  isOpen: PropTypes.bool,
  changelog: PropTypes.string,
  onClose: PropTypes.func
}

export default ChangelogModal
