import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Modal,
  Header,
  Icon
} from 'semantic-ui-react'
import Logo from '../../Static/Images/logo.png'

import './styles.css'

class Login extends React.Component {
  constructor () {
    super()
    this.state = {
      loginErroModalOpen: false,
      resetPasswordEnabled: false,
    }
    this.showPasswordResetForm = this.showPasswordResetForm.bind(this)
    this.hidePasswordResetForm = this.hidePasswordResetForm.bind(this)
    this.submit = this.submit.bind(this)
    this.submitResetPassword = this.submitResetPassword.bind(this)
  }

  showPasswordResetForm () {
    this.setState({ resetPasswordEnabled: true })
  }

  hidePasswordResetForm () {
    this.setState({ resetPasswordEnabled: false })
  }

  submit () {
    let userName = this.refs.userName.value
    let password = this.refs.password.value
    this.props.onSignIn(userName, password)
  }

  submitResetPassword () {
    let eMail = this.refs.eMail.value
    this.props.onPasswordReset(eMail)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.loginError) {
      this.setState({ loginErroModalOpen: true })
    }
  }

  render () {
    return (
      <section className='login-form'>
        <img src={Logo} alt='logo' />
        {!this.state.resetPasswordEnabled && (
          <Form>
            <div className='field'>
              <div className='ui left icon input'>
                <input ref='userName' type='text' placeholder='Username' />
                <i className='user icon' />
              </div>
            </div>
            <div className='field'>
              <div className='ui left icon input'>
                <input ref='password' type='password' placeholder='***********' />
                <i className='lock icon' />
              </div>
            </div>
            <Form.Field control={Button} onClick={this.submit}>Sign In</Form.Field>
            <p className='forgot-password'><a href='#' onClick={this.showPasswordResetForm}>Forgot Password</a></p>
          </Form>
        )}
        {this.state.resetPasswordEnabled && !this.props.passwordResetSuccess && (
          <Form>
            <p>Please insert your e-mail address and you'll receive instructions to reset your password</p>
            <div className='field'>
              <div className='ui left icon input'>
                <input ref='eMail' type='text' placeholder='E-mail' />
                <i className='envelope icon' />
              </div>
            </div>
            <Form.Field control={Button} onClick={this.submitResetPassword}>Send</Form.Field>
            <p className='forgot-password'><a href='#' onClick={this.hidePasswordResetForm}>Sign In</a></p>
          </Form>
        )}
        {this.state.resetPasswordEnabled && this.props.passwordResetSuccess && (
          <Form>
            <p>We sent you an e-mail to the provided address containing instructions to reset your password (check also the spam folder).</p>
            <p className='forgot-password'><a href='#' onClick={this.hidePasswordResetForm}>Sign In</a></p>
          </Form>
        )}
        <Modal open={this.state.loginErroModalOpen} basic size='small'>
          <Header icon='ban' content='Authentication Error' />
          <Modal.Content>
            <p>{this.props.loginError}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' inverted onClick={() => this.setState({ loginErroModalOpen: false })}>
              <Icon name='checkmark' /> Retry
            </Button>
          </Modal.Actions>
        </Modal>
      </section>
    )
  }
}

Login.propTypes = {
  onSignIn: PropTypes.func,
  onPasswordReset: PropTypes.func,
  loginError: PropTypes.string,
  passwordResetSuccess: PropTypes.bool
}

export default Login
