import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ResetPasswordForm from '../Components/ResetPasswordForm'
import {
  Container,
  Grid
} from 'semantic-ui-react'
import history from '../history'
import { api } from '../Sagas'

class ResetPasswordView extends React.Component {
  constructor () {
    super()
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
  }

  componentWillMount () {
    if (this.props.isAuthenticated) {
      history.push('/')
    }
  }

  async handlePasswordChange (password) {
    const res = await api.passwordResetConfirm(this.props.match.params.token, password)
    return res
  }

  render () {
    return (
      <div className='page-login'>
        <Container>
          <Grid doubling centered>
            <Grid.Column textAlign='center' mobile={16} tablet={8} computer={6}>
              <ResetPasswordForm
                onChangePassword={this.handlePasswordChange}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )
  }
}

ResetPasswordView.propTypes = {
  isAuthenticated: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    })
  }),
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView)
