import PropTypes from 'prop-types'
import React from 'react'
import { toast } from 'react-toastify'
import { Icon, Image, Popup } from 'semantic-ui-react'

import { version } from '../../../package.json'
import LogoOtto from '../../Static/Images/logo-otto.jpg'
import './styles.css'

class Footer extends React.Component {
  constructor() {
    super()
    this.state = {
      playing: false,
    }
  }
  render() {
    return (
      <footer className="app-footer">
        <div>v{version}</div>
        <div>
          <a href="https://www.otto.to.it">
            <Image src={LogoOtto} alt="otto" />
          </a>
        </div>
        <div>
          <Popup
            trigger={
              <Icon
                name="help circle"
                className="tour-icon"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={
                  this.props.onRunTour ? this.props.onRunTour : () => toast.info('No tour available for this route')
                }
              />
            }
            content="Start tour"
            position="top center"
          />
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  onRunTour: PropTypes.func,
}

export default Footer
