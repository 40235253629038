import React from 'react'
import EmailComposer from '../../Components/EmailComposer'
import { api } from '../../Sagas'
import { connect } from 'react-redux'
import NavBar from '../../Containers/NavBarContainer'
import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Dropdown,
  Header,
  Icon,
  Button,
  Label
} from 'semantic-ui-react'
import Footer from '../../Components/Footer'

import './styles.css'

class MailerView extends React.Component {
  constructor (props) {
    super(props)
    this.handleSend = this.handleSend.bind(this)
    this.addGroup = this.addGroup.bind(this)

    this.state = {
      groups: [],
      selectedGroups: [],
      loading: true,
      users: {},
      emailSent: false,
      emailResult: null
    }
  }

  componentDidMount () {
    api.groups().then(res => {
      this.setState({
        groups: res.data,
        loading: false
      })
    })
  }

  handleSend (data) {
    api.mailer(data).then(res => {
      if (res.ok) {
        this.setState({ emailSent: true, emailResult: true })
      } else {
        this.setState({ emailSent: true, emailResult: false })
      }
    })
  }

  addGroup (e, { value }) {
    let users = {}
    value.forEach(groupId => {
      let group = this.state.groups.filter(g => g.id === groupId)[0]
      group.users.forEach(user => {
        users[user.userId] = user
      })
    })
    this.setState({ users, selectedGroups: value })
  }

  content () {
    if (this.state.loading) {
      return (
        <div style={{ position: 'relative', minHeight: '200px' }}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </div>
      )
    } else if (this.state.emailSent) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '3rem',
            paddingBottom: '3rem'
          }}
        >
          <Header as='h2' icon>
            <Icon
              name={
                this.state.emailResult ? 'check circle' : 'exclamation circle'
              }
              color={this.state.emailResult ? 'green' : 'red'}
            />
            {this.state.emailResult ? 'Success' : 'Failed!'}
            <Header.Subheader>
              {this.state.emailResult
                ? 'The messages were succesfully sent'
                : 'There was an error seinding the e-mails'}
            </Header.Subheader>
          </Header>
          <p style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Button
              primary
              onClick={() =>
                this.setState({ emailSent: false, emailResult: null })
              }
            >
              Send {this.state.emailResult ? 'another' : 'again'}
            </Button>
          </p>
        </div>
      )
    }
    return (
      <div>
        <p style={{ margin: '2rem 0' }}>Select one or more user groups, then select one or more users and write your message.</p>
        <div style={{ marginBottom: '1rem' }}>
          <Dropdown
            className='icon'
            icon='users'
            labeled
            button
            fluid
            multiple
            selection
            options={this.state.groups.map(g => ({
              key: g.id,
              text: g.name,
              value: g.id
            }))}
            value={this.state.selectedGroups}
            onChange={this.addGroup}
          />
        </div>
        <EmailComposer
          users={Object.keys(this.state.users).map(id => this.state.users[id])}
          onSend={this.handleSend}
        />
      </div>
    )
  }

  render () {
    return (
      <div className='page-settings'>
        <NavBar />
        <Container as='main'>
          <Segment>
            <Label color='orange' ribbon>
              Mailer
            </Label>
            {this.content()}
          </Segment>
        </Container>
        <Footer onRunTour={this.runTour} />
      </div>
    )
  }
}

MailerView.propTypes = {}

const mapStateToProps = state => {
  return {}
}
const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailerView)
