import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  List,
  Modal,
  Header,
  Icon
} from 'semantic-ui-react'
import Logo from '../../Static/Images/logo.png'

import './styles.css'
import { propTypes } from 'react-live-clock'
import history from '../../history'

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      error: '',
      passwordResetSuccess: false,
    }
    this.goToLogin = this.goToLogin.bind(this)
  }

  async submit() {
    let password = this.refs.password.value
    let passwordRepeat = this.refs.passwordRepeat.value
    if (password !== passwordRepeat) {
      this.setState({ error: 'Passwords do not match' })
      return
    }
    const res = await this.props.onChangePassword(password)

    if (res.ok) {
      this.setState({ passwordResetSuccess: true })
    } else if (res && res.data && res.data.password) {
      this.setState({ error: 'There was an error resetting your password: ' + res.data.password.join(' - ') })
    } else {
      this.setState({ error: 'There was an error resetting your password' })
    }
  }

  goToLogin () {
    history.push('/login')
  }

  render() {
    return (
      <section className='password-reset-form'>
        <img src={Logo} alt='logo' />
        {!this.state.passwordResetSuccess && (
          <Form>
            <div className='password-rules'>
              <p>Choose your new password:</p>
              <List bulleted>
                <List.Item>your password can’t be too similar to your other personal information;</List.Item>
                <List.Item>your password must contain at least 8 characters;</List.Item>
                <List.Item>your password can’t be a commonly used password;</List.Item>
                <List.Item>your password can’t be entirely numeric;</List.Item>
              </List>
            </div>
            <div className='field'>
              <div className='ui left icon input'>
                <input ref='password' type='password' placeholder='New password' />
                <i className='lock icon' />
              </div>
            </div>
            <div className='field'>
              <div className='ui left icon input'>
                <input ref='passwordRepeat' type='password' placeholder='Repeat password' />
                <i className='lock icon' />
              </div>
            </div>
            <Form.Field control={Button} onClick={this.submit}>Change Password</Form.Field>
          </Form>
        )}
        {this.state.passwordResetSuccess && (
          <Form>
            <p>Your password has been changed</p>
            <Form.Field control={Button} onClick={this.goToLogin}>Sign In</Form.Field>
          </Form>
        )}
        <Modal open={!!this.state.error} basic size='small'>
          <Header icon='ban' content='Reset Password Error' />
          <Modal.Content>
            <p>{this.state.error}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' inverted onClick={() => this.setState({ error: '' })}>
              <Icon name='checkmark' /> Retry
            </Button>
          </Modal.Actions>
        </Modal>
      </section>
    )
  }
}

PasswordResetForm.propTypes = {
  onChangePassword: PropTypes.func.isRequired,
}

export default PasswordResetForm
