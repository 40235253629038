import md5 from 'js-md5'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Grid, Segment, Menu, Table, Header, Image, Message, Checkbox, Form, Icon, List, Button } from 'semantic-ui-react'

import Footer from '../../Components/Footer'
import config from '../../Config'
import NavBar from '../../Containers/NavBarContainer'
import profileActions from '../../Redux/Profile'
import { api }  from '../../Sagas'
import './styles.css'

class SettingsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: 'info',
      currentPassword: '',
      newPassword: '',
      newPassword2: '',
    }
    this.toggleReleaseNotification = this.toggleReleaseNotification.bind(this)
    this.toggleDocumentNotification = this.toggleDocumentNotification.bind(this)
    this.toggleCommentNotification = this.toggleCommentNotification.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  content() {
    const { activeItem } = this.state
    let content
    switch (activeItem) {
      case 'info':
        content = this.infoContent()
        break
      case 'notifications':
        content = this.infoNotifications()
        break
      case 'password':
        content = this.changePassword()
        break
      default:
        content = this.infoContent()
    }
    return content
  }

  infoContent() {
    return (
      <Table basic="very" celled collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header as="h4" image>
                <Image
                  circular
                  className="navbar-gravatar"
                  src={'https://www.gravatar.com/avatar/' + md5(this.props.userEmail) + '?d=' + config.defaultGravatar}
                />
              </Header>
            </Table.Cell>
            <Table.Cell>
              <strong>{this.props.userName}</strong>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>User ID</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{this.props.userId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>First name</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{this.props.userFirstName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>Last name</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{this.props.userLastName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>E-mail</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{this.props.userEmail}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>Role</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{this.props.isSuperUser ? 'admin' : 'user'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4">
                <Header.Content>Last login</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{this.props.userLastLogin ? moment(this.props.userLastLogin).format('LLL') : ''}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }

  toggleReleaseNotification(event, data) {
    const { checked } = data
    this.props.updateProfile(
      profileActions.updateProfileRequest(this.props.profile.id, {
        newReleaseNotification: checked,
      }),
    )
  }

  toggleDocumentNotification(event, data) {
    const { checked } = data
    this.props.updateProfile(
      profileActions.updateProfileRequest(this.props.profile.id, {
        newDocumentNotification: checked,
      }),
    )
  }

  toggleCommentNotification(event, data) {
    const { checked } = data
    this.props.updateProfile(
      profileActions.updateProfileRequest(this.props.profile.id, {
        newCommentNotification: checked,
      }),
    )
  }

  infoNotifications() {
    if (!this.props.profile) return null

    const { newReleaseNotification, newDocumentNotification, newCommentNotification } = this.props.profile

    return (
      <div>
        <Message
          icon="bell"
          header="Set your notifications preferences"
          content="You can enable or disable notifications for your account. Notifications are sent when a new mockup
          revision, a document or a new comment are published. You can set these notification options separately."
        />
        <Table basic="very" celled collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Be notified by e-mail when a new revision is released</Table.Cell>
              <Table.Cell>
                <Checkbox
                  toggle
                  label={newReleaseNotification ? 'ON' : 'OFF'}
                  checked={newReleaseNotification}
                  onChange={this.toggleReleaseNotification}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Be notified by e-mail when a new document is published</Table.Cell>
              <Table.Cell>
                <Checkbox
                  toggle
                  label={newDocumentNotification ? 'ON' : 'OFF'}
                  checked={newDocumentNotification}
                  onChange={this.toggleDocumentNotification}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Be notified by e-mail when a new comment is published</Table.Cell>
              <Table.Cell>
                <Checkbox
                  toggle
                  label={newCommentNotification ? 'ON' : 'OFF'}
                  checked={newCommentNotification}
                  onChange={this.toggleCommentNotification}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    )
  }

  changePassword() {
    return (
      <Form>
        <Message icon>
          <Icon name="info" />
          <div>
            <strong>Change password</strong>
            <List bulleted>
              <List.Item>your password can’t be too similar to your other personal information;</List.Item>
              <List.Item>your password must contain at least 8 characters;</List.Item>
              <List.Item>your password can’t be a commonly used password;</List.Item>
              <List.Item>your password can’t be entirely numeric;</List.Item>
            </List>
          </div>
        </Message>
        <div className="field">
          <Form.Input
            placeholder="Current password"
            value={this.state.currentPassword}
            icon="lock"
            type="password"
            onChange={(e, { value }) => this.setState({ currentPassword: value })}
          />
        </div>
        <div className="field">
          <Form.Input
            placeholder="New password"
            value={this.state.newPassword}
            icon="lock"
            type="password"
            onChange={(e, { value }) => this.setState({ newPassword: value })}
          />
        </div>
        <div className="field">
          <Form.Input
            placeholder="Repeat new password"
            value={this.state.newPassword2}
            icon="lock"
            type="password"
            onChange={(e, { value }) => this.setState({ newPassword2: value })}
          />
        </div>
        <div className='field'>
          <Form.Field onClick={this.handleChangePassword} control={Button} disabled={!this.state.currentPassword || !this.state.newPassword || !this.state.newPassword2}>Change password</Form.Field>
        </div>
      </Form>
    )
  }

  handleChangePassword = async () => {
    console.log('API', api) // eslint-disable-line
    if (this.state.newPassword !== this.state.newPassword2) {
      toast.error('New passwords do not match')
      return
    }
    const res = await api.changePassword({
      oldPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
    })

    if (res.status === 400) {
      if (res.data.newPassword) {
        toast.error(res.data.newPassword.join(', '))
      } else if (res.data.oldPassword) {
        toast.error('The current password is incorrect')
      }
    } else if (res.status === 204) {
      toast.success('Password changed successfully')
      this.setState({ currentPassword: '', newPassword: '', newPassword2: '' })
    } else {
      toast.error('Something went wrong')
    }
  }

  render() {
    const { activeItem } = this.state

    return (
      <div className="page-settings">
        <NavBar />
        <Container as="main">
          <Grid>
            <Grid.Column stretched width={12}>
              <Segment>{this.content()}</Segment>
            </Grid.Column>

            <Grid.Column width={4}>
              <Menu fluid vertical tabular="right">
                <Menu.Item name="info" active={activeItem === 'info'} onClick={this.handleItemClick} />
                <Menu.Item
                  name="notifications"
                  active={activeItem === 'notifications'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item name="password" active={activeItem === 'password'} onClick={this.handleItemClick} />
              </Menu>
            </Grid.Column>
          </Grid>
        </Container>
        <Footer onRunTour={this.runTour} />
      </div>
    )
  }
}

SettingsView.propTypes = {
  isSuperUser: PropTypes.bool,
  userId: PropTypes.number,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  profile: PropTypes.object,
  updateProfile: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    isSuperUser: state.auth.isSuperUser,
    userId: state.auth.userId,
    userName: state.auth.userName,
    userFirstName: state.auth.userFirstName,
    userLastName: state.auth.userLastName,
    userLastLogin: state.auth.userLastLogin,
    userEmail: state.auth.userEmail,
    profile: state.profile.data,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (action) => dispatch(action),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView)
